import React from 'react';
import AddUserKohala2 from '../components/AddUserKohala2';
//import '../css/CreateUser.css'
import '../../../css/UpdateUser.css';
import {Link} from 'react-router-dom';

const CreateUserKohala2 = () => {
    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    Create User Page
                    <Link className="close-icon" to="/kohala2">X</Link>
                </h1>
            </div>
            <AddUserKohala2/>
        </>
    )
}

export default CreateUserKohala2;
