import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import { GetCurrentUser } from '../auth/GetCurrentUser';
import { useHistory } from 'react-router-dom';


const Home = () => {

    const [userID, setUserID] = useState('');

    //const [userRole, setUserRole] = useState('');


    useEffect((e) => {
 
        const fetchUserID = async () => {
            // eslint-disable-next-line
            // const {userData, userEmail} = GetCurrentUser(); 

            const userData = GetCurrentUser();      
            // console.log("userData",userData);
            // console.log("userData.uid",userData.uid);
            setUserID(userData.uid);
        }
        fetchUserID();
        // eslint-disable-next-line
    }, []);

    const history = useHistory();

    const handleClick = async (e) => {

        try{
            // eslint-disable-next-line
            const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/staff' + `/${userID}`);

            const response = await fetch(Uri);
            //console.log("response",response);
            const json = await response.json();
            //console.log("json",json);
            const org = await json.org;

            if (org === "waianae"){     
                history.push('/waianae');
            } else if (org === "bigIsland"){      
                history.push('/bigisland');
            } else if (org === "waimanalo"){      
                history.push('/waimanalo');
            } else if (org === "kamaile"){      
                history.push('/kamaile');
            } else if (org === "molokai"){      
                history.push('/molokai');
            } else if (org === "kohala"){      
                history.push('/kohala');
            } else if (org === "laupahoehoe"){      
                history.push('/laupahoehoe');
            } else if (org === "kohala2"){      
                history.push('/kohala2');
            } else if (org === "laupahoehoe2"){      
                history.push('/laupahoehoe2');
            } else {
                console.log("User has no roles, please contact support@hia.com");
            }

        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div style={{margin: "6rem"}}>
            <h1>Home Page</h1>
            <div style={{margin: "1rem", marginTop:"2rem"}}>
                <Link style={{fontSize:"1.5rem"}}  onClick={handleClick} to="/waianae">Waianae</Link>
            </div>
            <div style={{margin: "1rem"}}>
                <Link style={{fontSize:"1.5rem"}} onClick={handleClick} to="/bigisland">Big Island</Link>
            </div>
            <div style={{margin: "1rem"}}>
                <Link style={{fontSize:"1.5rem"}} onClick={handleClick} to="/waimanalo">Waimanalo</Link>
            </div>
            <div style={{margin: "1rem"}}>
                <Link style={{fontSize:"1.5rem"}} onClick={handleClick} to="/kamaile">Kamaile</Link>
            </div>
            <div style={{margin: "1rem"}}>
                <Link style={{fontSize:"1.5rem"}} onClick={handleClick} to="/molokai">Molokai</Link>
            </div>
            <div style={{margin: "1rem"}}>
                <Link style={{fontSize:"1.5rem"}} onClick={handleClick} to="/kohala">Kohala</Link>
            </div>
            <div style={{margin: "1rem"}}>
                <Link style={{fontSize:"1.5rem"}} onClick={handleClick} to="/laupahoehoe">Laupahoehoe</Link>
            </div>
            <div style={{margin: "1rem"}}>
                <Link style={{fontSize:"1.5rem"}} onClick={handleClick} to="/kohala2">Kohala 2</Link>
            </div>
            <div style={{margin: "1rem"}}>
                <Link style={{fontSize:"1.5rem"}} onClick={handleClick} to="/laupahoehoe2">Laupahoehoe 2</Link>
            </div>

        </div>
    )
}

export default Home;
