import React from 'react';
import UpdateUserComp from '../components/UpdateUserComp';
import '../../../css/UpdateUser.css';
import {Link} from 'react-router-dom';

const UpdateUser = () => {
    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    Update User Details
                    <Link className="close-icon" to="/waianae">X</Link>
                </h1>
            </div>
            <UpdateUserComp/>
        </>  
    )
}

export default UpdateUser;
