import React, { useState } from 'react';
import { Login } from '../auth/Login';
import '../css/Login.css';
import { useHistory } from 'react-router-dom';

const LoginComp = () => {

    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    //const [showModal, setShowModal] = useState(false);


    //const [userRole, setUserRole] = useState('');

    const history = useHistory();

    const handleLogin = async (e) => {
        e.preventDefault();
        try{
            
            const response = await fetch('https://us-central1-paac-crud.cloudfunctions.net/app/api/staff', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body : JSON.stringify({
                    email: emailValue
                })
            });
            //console.log(response);
            const json = await response.json();
            const org = await json["0"].org;

            console.log("org", org);

            if (org === "waianae"){
                try {

                    await Login(emailValue, passwordValue);        
                    history.push('/waianae');
                } catch (e) {
                    setErrorMessage(e.message);
                }
            } else if (org === "bigIsland"){
                try {
                    await Login(emailValue, passwordValue);        
                    history.push('/bigisland');
                } catch (e) {
                    setErrorMessage(e.message);
                }
            } else if (org === "waimanalo"){
                try {
                    await Login(emailValue, passwordValue);        
                    history.push('/waimanalo');
                } catch (e) {
                    setErrorMessage(e.message);
                }
            } else if (org === "kamaile"){
                try {
                    await Login(emailValue, passwordValue);        
                    history.push('/kamaile');
                } catch (e) {
                    setErrorMessage(e.message);
                }
            } else if (org === "molokai"){
                try {
                    await Login(emailValue, passwordValue);        
                    history.push('/molokai');
                } catch (e) {
                    setErrorMessage(e.message);
                }
            } else if (org === "kohala"){
                try {
                    await Login(emailValue, passwordValue);        
                    history.push('/kohala');
                } catch (e) {
                    setErrorMessage(e.message);
                }
            } else if (org === "laupahoehoe"){
                try {
                    await Login(emailValue, passwordValue);        
                    history.push('/laupahoehoe');
                } catch (e) {
                    setErrorMessage(e.message);
                }
            } else if (org === "kohala2"){
                try {
                    await Login(emailValue, passwordValue);        
                    history.push('/kohala2');
                } catch (e) {
                    setErrorMessage(e.message);
                }
            } else if (org === "laupahoehoe2"){
                try {
                    await Login(emailValue, passwordValue);        
                    history.push('/laupahoehoe2');
                } catch (e) {
                    setErrorMessage(e.message);
                }
            }   else {
                setErrorMessage("User has no roles, please contact support@hia.com");
            }

        } catch (error) {
            console.log(error);
        }

    }

    //console.log("userRole 2",userRole);

    return (
        <div className="form-container">
            <h1>Login</h1>
            <form className="col g-3 align-items-center">
            <link href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.0.0-alpha/css/bootstrap.css" rel="stylesheet" />
                <div className="col-4 p-2 center-block">
                    <label className="form-label padding-xl">Email:</label>
                    <input className="form-control padding-xl" type="email" autoFocus required name='email' value={emailValue} placeholder='someone@gmail.com' onChange={e => setEmailValue(e.target.value)}></input>
                    <p className="text-danger">{errorMessage}</p>
                </div>
                <div className="col-4 p-2 center-block">
                    <label className="form-label padding-xl">Password:</label>
                    <input className="form-control padding-xl" type="password" autoFocus required name='password' value={passwordValue} placeholder='Password' onChange={e => setPasswordValue(e.target.value)}></input>
                    <p className="text-danger">{errorMessage}</p>
                </div>
            </form>
            <div className="d-grid gap-4 p-4 col-4 mx-auto">
                    <>
                        <button type="submit" className="btn btn-primary" onClick={handleLogin}>Login</button>
                        <div className="btn-container">
                            <p>Don't have an account? <span className="text-secondary" role="button" >Sign up here!</span></p>
                        </div> 
                    </>
            </div>
        </div>
    )
}

export default LoginComp;
