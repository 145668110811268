import firebase from 'firebase/app';

export const GetCurrentUser = () => {

    const user = firebase.auth().currentUser;
    
    //console.log("hi");
    // console.log("user wrapper func email",user.uid);

    if (!user) {
        return null;
    } else {
        // console.log("user wrapper func email",user.uid);
        const uid = user.uid;
        // console.log("Wrapper uid", uid);
        return {uid}; 
    }
    
} 
