import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { customAlphabet } from 'nanoid';

const CopyUserCompWaimanalo = (props) => {

    let history = useHistory();
    const nanoid = customAlphabet('1234567890', 8);
    const newRegistrationID = nanoid();

    // Old fields from existing record
    // eslint-disable-next-line
    const [registrationId, setRegistrationId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [phone1, setPhone1] = useState("");
    const [phone2, setPhone2] = useState("");
    const [personalEmail, setPersonalEmail] = useState("");
    const [parentEmail, setParentEmail] = useState("");
    const [appointmentDate, setAppointmentDate] = useState("");
    // eslint-disable-next-line
    const [appointmentTime, setAppointmentTime] = useState("");
    const [location, setLocation] = useState("");
    const [consentId, setConsentId] = useState("");
    const [consent, setConsent] = useState("");
    const [consentNote, setConsentNote] = useState("");
    // eslint-disable-next-line
    const [testResult, setTestResult] = useState("");
    // eslint-disable-next-line
    const [testDate, setTestDate] = useState("");
    // eslint-disable-next-line
    const [testNote, setTestNote] = useState("");
    const [payment, setPayment] = useState("");
    const [paymentNote, setPaymentNote] = useState("");
    const [smsStatus, setSmsStatus] = useState("");
    const [emailStatus, setEmailStatus] = useState("");
    const [techNote, setTechNote] = useState("");
    // eslint-disable-next-line
    const [symptom, setSymptom] = useState("");
    // eslint-disable-next-line
    const [exposure, setExposure] = useState("");
    // eslint-disable-next-line
    const [vaccination, setVaccination] = useState("");
    const [gender, setGender] = useState("");
    const [race, setRace] = useState("");
    const [address, setAddress] = useState("");
    // const [createdDate, setcreatedDate] = useState("");
    // const [latestRecord, setlatestRecord] = useState(false);
    // const [homeTestKit, setHomeTestKit] = useState("");


    // New fields that will/might change for new record
    const [newRegID, setNewRegID] = useState(newRegistrationID);
    const [newAppointmentDate, setNewAppointmentDate] = useState("");
    const [newAppointmentTime, setNewAppointmentTime] = useState("");
    const [newTestResult, setNewTestResult] = useState("");
    const [newTestDate, setNewTestDate] = useState("");
    const [newTestNote, setNewTestNote] = useState("");
    const [newSymptom, setNewSymptom] = useState("");
    const [newExposure, setNewExposure] = useState("");
    const [newVaccination, setNewVaccination] = useState("");
    // const [newCreatedDate, setNewCreatedDate] = useState("");
    // const [newlatestRecord, setNewlatestRecord] = useState("");
    // eslint-disable-next-line
    // const [newHomeTestKit, setNewHomeTestKit] = useState("");


    const [ race_NH, setrace_NH] = useState(false);
    const [ race_PI, setrace_PI] = useState(false);
    const [ race_C, setrace_C] = useState(false);
    const [ race_A, setrace_A] = useState(false);
    const [ race_NA, setrace_NA] = useState(false);
    const [ race_B, setrace_B] = useState(false);
    const [ race_O, setrace_O] = useState(false);


    const [ firstNameError, setFirstNameError] = useState("");
    const [ lastNameError, setLastNameError] = useState("");
    const [ dobError, setDobError] = useState("");
    const [ phone1Error, setPhone1Error] = useState("");
    const [ appointmentDateError, setAppointmentDateError] = useState("");


    const { registrationID } = useParams();

    //adding today's date to appointment date
    if (appointmentDate === ""){

        var currdate = new Date ();
        const year = currdate.getFullYear();
        const month = ("0" + (currdate.getMonth() + 1)).slice(-2);
        const day = ("0" + currdate.getDate()).slice(-2);
        const today = month + "/" + day + "/" + year;

        setAppointmentDate(today);
    }

    useEffect(() => {
        const fetchData = async () => {
            // eslint-disable-next-line
            const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/waimanalousers' + `/${registrationID}`);
            //console.log(Uri);
            try {
                const response = await fetch(Uri);
                const data = await response.json();

                setRegistrationId(data.registrationID)
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setDob(data.dob);
                setPhone1(data.phone1);
                setPhone2(data.phone2);
                setPersonalEmail(data.personalEmail);
                setParentEmail(data.parentEmail);
                setAppointmentDate(data.appointmentDate);
                setAppointmentTime(data.appointmentTime);
                setLocation(data.location);
                setConsentId(data.consentID);
                setConsent(data.consent);
                setConsentNote(data.consentNote);
                setTestResult(data.testResult);
                setTestDate(data.testDate);
                setTestNote(data.testNote);
                setPayment(data.payment);
                setPaymentNote(data.paymentNote);
                setSmsStatus(data.smsStatus);
                setEmailStatus(data.emailStatus);
                setTechNote(data.techNote);
                setSymptom(data.symptom);
                setExposure(data.exposure);
                setVaccination(data.vaccination);
                setGender(data.gender);
                setRace(data.race);
                setAddress(data.address);
                // setcreatedDate(data.createdDate);
                // setlatestRecord(data.latestRecord);
                // setHomeTestKit(data.homeTestKit);

            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, []);


    const handleChangeNH = async (e) => {
        setrace_NH(!race_NH);
    }

    const handleChangePI = async (e) => {
        setrace_PI(!race_PI);
    }

    const handleChangeC = async (e) => {
        setrace_C(!race_C);
    }

    const handleChangeA = async (e) => {
        setrace_A(!race_A);
    }

    const handleChangeNA = async (e) => {
        setrace_NA(!race_NA);
    }

    const handleChangeB = async (e) => {
        setrace_B(!race_B);
    }

    const handleChangeO = async (e) => {
        setrace_O(!race_O);
    }


    //NEW
    const handleCopyUser = async (e) => {
        e.preventDefault(); //supressing default behavior for submit buttons only
        
        let finalrace = "";
        
        if (race_NH){
            finalrace = finalrace + "Native Hawaiian"; 
        }
        if (race_PI){
            if (finalrace.length === 0) {
                finalrace = finalrace + "Pacific Islander";
            }
            else{
                finalrace = finalrace + ",Pacific Islander";
            }
        }
        if (race_C){
            if (finalrace.length === 0) {
                finalrace = finalrace + "Caucasian";
            }
            else{
                finalrace = finalrace + ",Caucasian";
            }
        }
        if (race_A){
            if (finalrace.length === 0) {
                finalrace = finalrace + "Asian";
            }
            else{
                finalrace = finalrace + ",Asian";
            }
        }
        if (race_NA){
            if (finalrace.finalrace === 0) {
                finalrace = finalrace + "Native American";
            }
            else{
                finalrace = finalrace + ",Native American";
            }
        }
        if (race_B){
            if (finalrace.finalrace === 0) {
                finalrace = finalrace + "Black";
            }
            else{
                finalrace = finalrace + ",Black";
            }
        }
        if (race_O){
            if (finalrace.length === 0) {
                finalrace = finalrace + "Others";
            }
            else{
                finalrace = finalrace + ",Others";
            }
        }

        setRace(finalrace);

        
        if (firstName.length === 0){
            setFirstNameError("Required field!");
            //console.log("First Name is a required field!");
        } else if (lastName.length === 0){
            setLastNameError("Required field!");
        } else if (dob.length === 0){
            setDobError("Required field!");
        } else if (phone1.length === 0){
            setPhone1Error("Required field!")
        } else if (appointmentDate.length === 0){
            setAppointmentDateError("Required field!");
        } else {

            // if everything is right and there are no errors

            //// FIRST UPDATE THE EXISTING RECORD latestRecord field
            // if (latestRecord === true) {
            //     setlatestRecord(false);
            // }
            
            // try {
            //     // eslint-disable-next-line
            //     const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/kohalausers/update' + `/${registrationID}`);
            //     // eslint-disable-next-line
            //     const response = await fetch(Uri, {
            //             method: 'PUT',
            //             headers: { 'Content-Type': 'application/json' },
            //             body : JSON.stringify({
            //                 registrationID: registrationId,
            //                 firstName: firstName,
            //                 lastName: lastName,
            //                 dob: dob,
            //                 phone1: phone1,
            //                 phone2: phone2,
            //                 personalEmail: personalEmail,
            //                 parentEmail: parentEmail,
            //                 appointmentDate: appointmentDate,
            //                 appointmentTime: appointmentTime,
            //                 location: location,
            //                 consentID: consentId,
            //                 consent: consent,
            //                 consentNote: consentNote,
            //                 testResult: testResult,
            //                 testDate: testDate,
            //                 testNote: testNote,
            //                 payment: payment,
            //                 paymentNote: paymentNote,
            //                 smsStatus: smsStatus,
            //                 emailStatus: emailStatus,
            //                 techNote: techNote,
            //                 symptom: symptom,
            //                 exposure: exposure,
            //                 vaccination: vaccination,
            //                 gender: gender,
            //                 race: race,
            //                 address: address,
            //                 createdDate: createdDate,
            //                 latestRecord: false
            //             })
            //         });
                
            //     // console.log(response);
            //     // history.push("/kamaile");

            //     // console.log("Updated old record latestRecord=false")
            // } catch (error) {
            //     console.log(error);
            // }



            //// Then create new user record

            try {
                //const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/waianaeusers/update' + `/${registrationID}`);
                // eslint-disable-next-line
                    const response = await fetch('https://us-central1-paac-crud.cloudfunctions.net/app/api/waimanalousers/create', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body : JSON.stringify({
                            registrationID: newRegID,
                            firstName: firstName,
                            lastName: lastName,
                            dob: dob,
                            phone1: phone1,
                            phone2: phone2,
                            personalEmail: personalEmail,
                            parentEmail: parentEmail,
                            appointmentDate: newAppointmentDate,
                            appointmentTime: newAppointmentTime,
                            location: location,
                            consentID: consentId,
                            consent: consent,
                            consentNote: consentNote,                            
                            testResult: newTestResult,
                            testDate: newTestDate,
                            testNote: newTestNote,
                            payment: payment,
                            paymentNote: paymentNote,
                            smsStatus: smsStatus,
                            emailStatus: emailStatus,
                            techNote: techNote,
                            symptom: newSymptom,
                            exposure: newExposure,
                            vaccination: newVaccination,
                            gender: gender,
                            race: race,
                            address: address,

                            // homeTestKit: homeTestKit,
                            // createdDate: newCreatedDate,
                            // latestRecord: newlatestRecord
                        })
                    });
                    //console.log("hi", finalrace);
                    // console.log("New user copy created with latestRecord=true")
                    history.push("/waimanalo");
                } catch (error) {
                    console.log(error);
                }

            
        }
    }


    //Autofill test date to current date when empty 
    if (newAppointmentDate === ""){

        var currdate2 = new Date ();
        //console.log(currdate2);
        const year = currdate2.getFullYear();
        const month = ("0" + (currdate2.getMonth() + 1)).slice(-2);
        const day = ("0" + currdate2.getDate()).slice(-2);
        //console.log(month, day, year);
        const today = month + "/" + day + "/" + year;
        //console.log(today);

        setNewAppointmentDate(today);
        setNewTestDate(today);
        // setNewCreatedDate(today);

        // setNewlatestRecord(true);
    }

    return (
        <div className="custom-update-container">
            <form action="">
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <div className="row">
                                <div className="col">
                                    <label className="required-field" htmlFor="firstname">First Name</label>
                                </div>
                                <div className="col">
                                    { firstNameError.length > 0 ? (
                                    <p style={{color: "red", fontSize:"small", marginLeft: `-150px`, padding:"none" }} >{firstNameError}</p>
                                    ) : null }
                                </div>
                            </div>
                            <input value={firstName} onChange={e => {setFirstName(e.target.value); setFirstNameError("")}} type="text" className="form-control" placeholder="First Name"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <div className="row">
                                <div className="col">
                                    <label className="required-field" htmlFor="lastname">Last Name</label>
                                </div>
                                <div className="col">
                                    { lastNameError.length > 0 ? (
                                    <p style={{color: "red", fontSize:"small", marginLeft: `-150px`, padding:"none" }} >{lastNameError}</p>
                                    ) : null }
                                </div>
                            </div>
                            <input value={lastName} onChange={e => {setLastName(e.target.value); setLastNameError("")}} type="text" className="form-control" placeholder="Last Name"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <label className="required-field" htmlFor="registrationid">Registration ID</label>
                            <input disabled value={newRegID} onChange={e => setNewRegID(e.target.value)} type="number" className="form-control" placeholder="#########"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <div className="row">
                                <div className="col">
                                    <label className="required-field" htmlFor="dob">Date of Birth</label>
                                </div>
                                <div className="col">
                                    { dobError.length > 0 ? (
                                    <p style={{color: "red", fontSize:"small", marginLeft: `-50px`, padding:"none" }} >{dobError}</p>
                                    ) : null }
                                </div>
                            </div>
                            <input value={dob} onChange={e => {setDob(e.target.value); setDobError("")}} type="text" className="form-control" placeholder="Date of birth (mm/dd/yyyy)"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="personalemail">Email Address</label>
                            <input value={personalEmail} onChange={e => setPersonalEmail(e.target.value)} type="email" className="form-control" placeholder="someone@gmail.com"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <div className="row">
                                <div className="col">
                                    <label className="required-field" htmlFor="phone1">Primary Phone</label>
                                </div>
                                <div className="col">
                                    { phone1Error.length > 0 ? (
                                    <p style={{color: "red", fontSize:"small", marginLeft: `-50px`, padding:"none" }} >{phone1Error}</p>
                                    ) : null }
                                </div>
                            </div>
                            <input value={phone1} onChange={e => {setPhone1(e.target.value); setPhone1Error("")}} type="text" className="form-control" placeholder="##########"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="phone2">Secondary Phone</label>
                            <input value={phone2} onChange={e => setPhone2(e.target.value)} type="text" className="form-control" placeholder="##########"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="parentemail">Parent's Email Address</label>
                            <input value={parentEmail} onChange={e => setParentEmail(e.target.value)} type="email" className="form-control" placeholder="someone@gmail.com"></input>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="gender">Gender</label>
                            <select id="gender" className="input-group-text custom-drops" value={gender} onChange={e => setGender(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>


                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="address">Address</label>
                            <input id="address" value={address} onChange={e => setAddress(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col">
                        <label htmlFor="Race">Race: </label>
                        <label className="label-custom">
                            <input 
                                type="checkbox"
                                label="NH"
                                value={race_NH}
                                onChange={handleChangeNH}
                            />
                            Native Hawaiian
                        </label>
                        <label className="label-custom">
                            <input 
                                type="checkbox"
                                label="PI"
                                value={race_PI}
                                onChange={handleChangePI}
                            />
                            Pacific Islander
                        </label>
                        <label className="label-custom">
                            <input 
                                type="checkbox"
                                label="C"
                                value={race_C}
                                onChange={handleChangeC}
                            />
                            Caucasian
                        </label>
                        <label className="label-custom">
                            <input 
                                type="checkbox"
                                label="A"
                                value={race_A}
                                onChange={handleChangeA}
                            />
                            Asian
                        </label>
                        <label className="label-custom">
                            <input 
                                type="checkbox"
                                label="NA"
                                value={race_NA}
                                onChange={handleChangeNA}
                            />
                            Native American
                        </label>
                        <label className="label-custom">
                            <input 
                                type="checkbox"
                                label="B"
                                value={race_B}
                                onChange={handleChangeB}
                            />
                            Black
                        </label>
                        <label className="label-custom">
                            <input 
                                type="checkbox"
                                label="O"
                                value={race_O}
                                onChange={handleChangeO}
                            />
                            Other
                        </label>

                    </div>
                </div>



                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <div className="row">
                                <div className="col">
                                    <label className="required-field" htmlFor="appointmentdate">Appointment Date</label>
                                </div>
                                <div className="col">
                                    { appointmentDateError.length > 0 ? (
                                    <p style={{color: "red", fontSize:"small", marginLeft: `-40px`, padding:"none" }} >{appointmentDateError}</p>
                                    ) : null }
                                </div>
                            </div>
                            <input value={newAppointmentDate} onChange={e => {setNewAppointmentDate(e.target.value); setAppointmentDateError("")}} type="text" className="form-control" placeholder="mm/dd/yyyy"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="appointmenttime">Appointment Time</label>
                            <input value={newAppointmentTime} onChange={e => setNewAppointmentTime(e.target.value)} type="text" className="form-control" placeholder=""></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="location">Location</label>
                            <select id="location" className="input-group-text" value={location} onChange={e => setLocation(e.target.value)}>
                                <option disabled>-- Location --</option>
                                <option value="waimanalo">Waimanalo</option>
                                <option value="waianae">Waianae High School</option>
                                <option value="kohala">Kohala Highschool</option>
                                <option value="laupahoehoe">Laupahoehoe Charter School</option>
                                <option value="kamaile">Kamaile</option>
                                <option value="bigisland">Big Island</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="testresult">Test Result</label>
                            <select id="testresult" className="input-group-text" value={newTestResult} onChange={e => setNewTestResult(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Positive">Positive</option>
                                <option value="Negative">Negative</option>
                                <option value="Invalid">Invalid</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="testdate">Test Date</label>
                            <input value={newTestDate} onChange={e => setNewTestDate(e.target.value)} type="text" className="form-control" placeholder="mm/dd/yyyy"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="testnote">Test Note</label>
                            <input value={newTestNote} onChange={e => setNewTestNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-dropdowns">
                        <div className="form-group my-1">
                            <label htmlFor="symptom">Symptom</label>
                            <select id="symptom" className="input-group-text custom-drops" value={newSymptom} onChange={e => setNewSymptom(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-dropdowns">
                        <div className="form-group my-1">
                            <label htmlFor="exposure">Exposure</label>
                            <select id="exposure" className="input-group-text custom-drops" value={newExposure} onChange={e => setNewExposure(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-dropdowns">
                        <div className="form-group my-1">
                            <label htmlFor="vaccination">Vaccination</label>
                            <select id="vaccination" className="input-group-text custom-drops" value={newVaccination} onChange={e => setNewVaccination(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>

                    {/* <div className="col-dropdowns">
                        <div className="form-group my-1">
                            <label htmlFor="homeTestKit">Home Test Kit</label>
                            <select id="homeTestKit" className="input-group-text custom-drops" value={newHomeTestKit} onChange={e => setHomeTestKit(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div> */}

                </div>

                <div className="row">
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="consentid">Consent ID</label>
                            <input id="consentid" value={consentId} onChange={e => setConsentId(e.target.value)} type="number" className="form-control" placeholder="######"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="consent">Consent</label>
                            <select id="consent" className="input-group-text" value={consent} onChange={e => setConsent(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="consentnote">Consent Note</label>
                            <input id="consentnote" value={consentNote} onChange={e => setConsentNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="payment">Payment</label>
                            <input id="payment" value={payment} onChange={e => setPayment(e.target.value)} type="float" className="form-control" placeholder="$$"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="paymentnote">Payment Note</label>
                            <input id="paymentnote" value={paymentNote} onChange={e => setPaymentNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="smsstatus">SMS Status</label>
                            <input id="smsstatus" value={smsStatus} onChange={e => setSmsStatus(e.target.value)} type="text" className="form-control" placeholder="SMS Status"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="emailstatus">Email Status</label>
                            <input id="emailstatus" value={emailStatus} onChange={e => setEmailStatus(e.target.value)} type="text" className="form-control" placeholder="Email Status"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="technote">Tech Note</label>
                            <input id="technote" value={techNote} onChange={e => setTechNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>

                <div className="d-grid col-3 mx-auto my-4">
                    <button type="submit" className="btn btn-primary" onClick={handleCopyUser}>Create</button>
                </div>

            </form>
        </div>
    )

}

export default CopyUserCompWaimanalo;
