import React from 'react';
import UserDetailsCompKohala2 from '../components/UserDetailsCompKohala2';
import '../../../css/UpdateUser.css';

const UserDetailsKohala2 = () => {

    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    User Details Page
                </h1>
            </div>
            <UserDetailsCompKohala2 />
        </>  
    )
}

export default UserDetailsKohala2;
