import React from 'react';
import UpdateUserCompBI from '../components/UpdateUserCompBI';
import '../../../css/UpdateUser.css';
import {Link} from 'react-router-dom';

const UpdateUserBI = () => {
    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    Update User Details
                    <Link className="close-icon" to="/bigisland">X</Link>
                </h1>
            </div>
            <UpdateUserCompBI/>
        </>  
    )
}

export default UpdateUserBI;
