import React from 'react';
import AddUserKamaile from '../components/AddUserKamaile';
import '../../../css/UpdateUser.css';
import {Link} from 'react-router-dom';

const CreateUser = () => {
    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    Create User Page
                    <Link className="close-icon" to="/kamaile">X</Link>
                </h1>
            </div>
            <AddUserKamaile />
        </>
    )
}

export default CreateUser;
