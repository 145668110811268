import firebase from 'firebase/app';

export const Login = async (email, password) => {
    try {
        // eslint-disable-next-line
        const result = await firebase.auth().signInWithEmailAndPassword(email, password);
        return {};
    } catch (e) {
        throw new Error('Error logging in!');
    }
} 