import React from 'react';
import PrintCompWaimanalo from '../components/PrintCompWaimanalo';
import '../../../css/PrintPage.css';
import {Link} from 'react-router-dom';

const PrintPage = () => {
    return (
        <div>
            <div className="custom-update">
                <div className="row">
                    <div className="column">
                        <h1 className="text-center">Participant Details</h1>
                    </div>
                    <div className="column">
                        <Link className="close-icon no-print" to="/waimanalo">X</Link>
                    </div>
                </div>  
            </div>
            <PrintCompWaimanalo/>
        </div>
    )
}

export default PrintPage;
