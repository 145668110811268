import React from 'react';
import UpdateUserCompLaupahoehoe2 from '../components/UpdateUserCompLaupahoehoe2';
import '../../../css/UpdateUser.css';
import {Link} from 'react-router-dom';

const UpdateUserLaupahoehoe2 = () => {
    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    Update User Details
                    <Link className="close-icon" to="/laupahoehoe2">X</Link>
                </h1>
            </div>
            <UpdateUserCompLaupahoehoe2/>
        </>  
    )
}

export default UpdateUserLaupahoehoe2;
