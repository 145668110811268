import React from 'react';
import { Logout } from '../auth/Logout';

const LogoutComp = () => {

    const handleLogout = async () => {
        try {
            await Logout();
        } catch (e) {
            alert(e.message);
        }
    };

    return (
        <div className="d-flex justify-content-end">
            <button onClick={handleLogout} className="btn btn-dark">Logout</button>
        </div>
    )
}

export default LogoutComp;
