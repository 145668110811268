import React from 'react';
import {useState,useEffect} from 'react';
import '../../../css/UserList.css';
//import { Link } from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import { MdPrint } from 'react-icons/md';


const SearchUserByDateLaupahoehoe2 = (props) => {

    let history = useHistory();

    const [userList, setUserList] = useState([]);
  
    //For search user by dob
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [year, setYear] = useState("");

    //For search user by reg id
    const [regID, setRegID] = useState("");

    //For search user by name
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");


    const [searchType, setSearchType] = useState("byDob");
    
    const handleSubmit = async (e) => {
        e.preventDefault(); //will prevent default reloading of the page (thereby not losing states)
        try{
            const date = month + "/" + day + "/" + year;
            const response = await fetch('https://us-central1-paac-crud.cloudfunctions.net/app/api/laupahoehoeusers2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body : JSON.stringify({
                    date: date
                })
            });
            //console.log(response);
            const json = await response.json();

            //console.log("json");
            //console.log(json);

            setUserList(json);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit2 = async (e) => {
        e.preventDefault(); //will prevent default reloading of the page (thereby not losing states)
        try{
            const registrationID = regID;
            // eslint-disable-next-line
            const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/laupahoehoeusers2' + `/${registrationID}`);

            //console.log(Uri);
            const response = await fetch(Uri);
            const data = await response.json();
            const json = [data];

            //console.log("json2");
            //console.log(json);

            setUserList(json);


        } catch (error) {
            console.log(error);
        }
    }



    const handleSubmit3 = async (e) => {
        e.preventDefault(); //will prevent default reloading of the page (thereby not losing states)


        //Logic for Checking which field is entered
        if (firstName !== "" && lastName === ""){ //if firstname is entered and no last name, hit firstname API

            try{
                const response = await fetch('https://us-central1-paac-crud.cloudfunctions.net/app/api/laupahoehoeusers2/firstname', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body : JSON.stringify({
                        firstName: firstName
                    })
                });
                //console.log(response);
                const json = await response.json();
    
                //console.log("json");
                //console.log(json);
    
                setUserList(json);
            } catch (error) {
                console.log(error);
            }

        } else if (lastName !== "" && firstName === ""){ //if lastname is entered and no last name, hit lastname API
            
            try{
                const response = await fetch('https://us-central1-paac-crud.cloudfunctions.net/app/api/laupahoehoeusers2/lastname', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body : JSON.stringify({
                        lastName: lastName
                    })
                });
                //console.log(response);
                const json = await response.json();
    
                //console.log("json");
                //console.log(json);
    
                setUserList(json);
            } catch (error) {
                console.log(error);
            }

        } else if ( firstName !== "" && lastName !== "") {

            try{
                const response = await fetch('https://us-central1-paac-crud.cloudfunctions.net/app/api/laupahoehoeusers2/firstname', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body : JSON.stringify({
                        firstName: firstName
                    })
                });
                //console.log(response);
                const json = await response.json();
    
                //console.log("json");
                //console.log(json);
    
                setUserList(json);

                if ( Object.keys(json).length  === 0) { //if no results in firstname, check last name

                    try{
                        const response = await fetch('https://us-central1-paac-crud.cloudfunctions.net/app/api/laupahoehoeusers2/lastname', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body : JSON.stringify({
                                lastName: lastName
                            })
                        });
                        //console.log(response);
                        const json = await response.json();
            
                        //console.log("json");
                        //console.log(json);
            
                        setUserList(json);
                    } catch (error) {
                        console.log(error);
                    }


                }
            } catch (error) {
                console.log(error);
            }
        }
    }



    //NEW
    const handleUserSelect = (registrationID) => {
        history.push(`/laupahoehoeusers2/${registrationID}`);
    }

    //NEW
    const handleUpdate = (e, registrationID) => {
        e.stopPropagation();
        history.push(`/laupahoehoeusers2/${registrationID}/update`);
    }

    const handlePrint = (e, registrationID) => {
        e.stopPropagation();
        history.push(`/laupahoehoeusers2/${registrationID}/print`);
    }

    //NEW
    useEffect(() => {
        
        const fecthData = async () => {
            try {
                    const date = month + "/" + day + "/" + year;
                    const response = await fetch('https://us-central1-paac-crud.cloudfunctions.net/app/api/laupahoehoeusers2', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body : JSON.stringify({
                            date: date
                        })
                    });
                    const data = await response.json();
                    setUserList(data);
                } catch (error) {
                    console.log(error);
                }
            }
            fecthData();
            // eslint-disable-next-line
    },[])


    return (
        <div>

            <div className="dropdown-wrapper">
                <div className="form-group form-control-lg">
                    <select id="searchType" className="input-group-text2 bg-light" value={searchType} onChange={e => setSearchType(e.target.value)}>
                        <option value="byRegid">Search User by Registration ID</option>
                        <option defaultValue value="byDob">Search User by Date of Birth</option>
                        <option value="byName">Search User by First and Last Name</option>
                    </select>
                </div>  
            </div>


            <form className="custom-form">

                {searchType === "byRegid" ?
                    <div className="row">
                        <div className="search-wrapper">
                            <div className="row">
                                <div className="col-3">
                                    <h4>Enter the Registration ID:</h4>
                                </div>
                                <div className="col-4">
                                    <input value={regID} onChange={e => setRegID(e.target.value)} type="text" className="form-control" placeholder="Registration ID"></input>
                                </div>
                                <div className="col-1">
                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit2}>Search</button>
                                </div>
                            </div>    
                        </div>     
                    </div> : null
                }



                { searchType === "byDob" ? 
                    <div className="row">
                        <div className="search-wrapper2">
                            <div className="row">
                                <div className="col-3">
                                    <h4>Enter the Date of Birth:</h4>
                                </div>  
                                <div className="col-3">
                                    <input value={month} onChange={e => setMonth(e.target.value)} type="text" className="form-control" placeholder="Month (MM)"></input>
                                </div>
                                <div className="col-3">
                                    <input value={day} onChange={e => setDay(e.target.value)} type="text" className="form-control" placeholder="Day (DD)"></input>
                                </div>
                                <div className="col-3">
                                    <input value={year} onChange={e => setYear(e.target.value)} type="text" className="form-control" placeholder="Year (YYYY)"></input>
                                </div>
                                <div className="col-1">
                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Search</button>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }


                { searchType === "byName" ?
                    <div className="row">
                        <div className="search-wrapper3">
                            <div className="row">
                                <div className="col-3">
                                    <h4>Enter the First or Last Name:</h4>
                                </div>  
                                <div className="col-3">
                                    <input value={firstName} onChange={e => setFirstName(e.target.value)} type="text" className="form-control" placeholder="First Name"></input>
                                </div>
                                <div className="col-3">
                                    <input value={lastName} onChange={e => setLastName(e.target.value)} type="text" className="form-control" placeholder="Last Name"></input>
                                </div>
                                <div className="col-1">
                                    <button type="submit" className="btn btn-primary" onClick={handleSubmit3}>Search</button>
                                </div>
                            </div>
                        </div>
                    </div> : null
                }


            </form>


            <div className="list-group">
            <table className="table table-hover">
                <thead>
                    <tr className="bg-info">
                        <th scope="col-auto">Registration ID</th>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col-auto">DOB</th>
                        <th scope="col-auto">Appointment Date</th>
                        <th scope="col-auto">Test Date</th>
                        <th scope="col-auto">Test Result</th>
                        <th scope="col-auto">Update</th>
                    </tr>
                </thead>
                <tbody>
                {userList && userList.map((user) => {
                        return (
                                <tr onClick={() => handleUserSelect(user.registrationID)} key={user.registrationID}>
                                    <td>{user.registrationID}</td>
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td>{user.dob}</td>
                                    <td>{user.appointmentDate}</td>
                                    <td>{user.testDate}</td>
                                    <td>{user.testResult}</td>

                                    <td><button type="button" onClick={(e) => handleUpdate(e, user.registrationID)} className="btn btn-outline-danger">Update</button></td>

                                    <td><MdPrint className="print-icon" onClick={(e) => handlePrint(e, user.registrationID)}/></td>
                                </tr>
                        )
                    })}
                </tbody>
            </table> 
        </div>
        </div>
    )
}

export default SearchUserByDateLaupahoehoe2;

