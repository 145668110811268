//import { render } from '@testing-library/react';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../../../css/PrintComp.css';

const PrintComp = (props) => {

    //let history = useHistory();

    const [registrationId, setRegistrationId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [techNote, setTechNote] = useState("");
    const [phone1, setPhone1] = useState("");
    const [phone2, setPhone2] = useState("");
    const [personalEmail, setPersonalEmail] = useState("");

    const [symptom, setSymptom] = useState("");
    const [exposure, setExposure] = useState("");
    const [vaccination, setVaccination] = useState("");

    const { registrationID } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            // eslint-disable-next-line
            const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/molokaiusers' + `/${registrationID}`);

            console.log(Uri);
            try {
                const response = await fetch(Uri);
                const data = await response.json();

                setRegistrationId(data.registrationID)
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setDob(data.dob);
                setTechNote(data.techNote);
                setPhone1(data.phone1);
                setPhone2(data.phone2);
                setPersonalEmail(data.personalEmail);

                setSymptom(data.symptom);
                setExposure(data.exposure);
                setVaccination(data.vaccination);

            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, []);


    //var regID5 = registrationID.substring(3, 8); // DOESN'T WORK FOR 9 DIGIT IDs

    var regID5 = registrationID.slice(-5); // WORKS FOR ALL IDs


    return (
    <>
        <div className="wrapper">
            <table className="table2">
                <tbody>
                    <tr className="row2">
                        <td className="label">Registration ID:</td>
                        <td className="value">{registrationId}</td>
                    </tr>
                    <tr className="row2">
                        <td className="label">Last 5-digits:</td>
                        <td className="value">{regID5}</td>
                    </tr>
                    <tr className="row2">
                        <td className="label">Full Name:</td>
                        <td className="value-name">{firstName} {lastName}</td>
                    </tr>
                    <tr className="row2">
                        <td className="label">Phone:</td>
                        <td className="value-name">{phone1}</td>

                        {phone2.length > 0 &&
                            <>  
                                <td className="value-name"> / {phone2}</td>
                            </>
                        }
                        
                    </tr>
                    <tr className="row2">
                        <td className="label">Email:</td>
                        <td className="value">{personalEmail}</td>
                    </tr>
                    <tr className="row2">
                        <td className="label">Date of Birth:</td>
                        <td className="value">{dob}</td>
                    </tr>
                    <tr className="row2">
                        <td className="label">Test Result:</td>
                        <td className="value-result">☐ Positive </td>
                        <td className="value-result2">☐ Negative </td>
                        <td className="value-result2">☐ Invalid </td>
                    </tr>
                </tbody>
            </table>
        </div>


        <div className="wrapper2">
            <table className="table3">
                <tbody>
                    <tr className="additional-info">
                        <td className="label3">Testing Site:</td>
                        <td className="value2">{techNote}</td>
                    </tr>

                    <tr className="additional-info">
                        <td className="label3">Participant Type:</td>
                        <td className="value2"> 
                            <div className="row">
                                <div className="column">
                                    ☐ Returning
                                </div>
                                <div className="column">
                                    ☐ New: Registered
                                </div>
                                <div className="column">
                                    ☐ Walk-In Reg.
                                </div>
                            </div>   
                        </td>
                    </tr>

                    <tr className="additional-info">
                        <td className="label3">Screening:</td>
                        <td className="value2">      
                            <div className="row">
                                <div className="column">
                                    <div className="row-ops">
                                        ☐ Symptoms {/*( YES |  NO  |  NA)*/} - {symptom}
                                    </div>
                                    <div className="row-ops">
                                        ☐ Exposure {/*( YES |  NO  |  NA)*/} - {exposure}
                                    </div>
                                    <div className="row-ops">
                                        ☐ Vaccination {/*( YES |  NO  |  NA)*/} - {vaccination}
                                    </div>
                                    <div className="row-ops">
                                        ☐ {"<"}18 ( YES |  NO  |  NA)
                                    </div>
                                    
                                </div>
                                {/*<div className="column">
                                    ☐ Exposure
                                </div>
                                <div className="column">
                                    ☐ Vacc.
                                </div>
                                <div className="column">
                                    ☐ {"<"}18
                                </div>*/}
                            </div>   
                        </td>
                    </tr>

                    <tr className="additional-info">
                        <td className="label3">If {"<"}18, School?</td>
                        <td className="value2"> </td>
                    </tr>

                    <tr className="additional-info">
                        <td className="label3">Healthcare patient?</td>
                        <td className="value2">
                            <div className="row">
                                <div className="column">
                                    ☐ YES
                                </div>
                                <div className="column">
                                    ☐ NO
                                </div>
                            </div>  
                        </td>
                    </tr>


                    <h4 className="test-info">Test Info:</h4>

                    <tr className="additional-info">
                        <td className="label4">Test Started:</td>
                        <td className="value4"> </td>
                    </tr>
                    <tr className="additional-info">
                        <td className="label4">Results Ready:</td>
                        <td className="value4"> </td>
                    </tr>

                    <div><h4> </h4></div>

                    <tr className="additional-info">
                        <td className="label4">Antigen Test Result:</td>
                        <td className="value4">
                            <div className="row">
                                <div className="column">
                                    ☐ Negative 
                                </div>
                                <div className="column">
                                    ☐ Positive 
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr className="additional-info">
                        <td className="label4">Returning for PCR?</td>
                        <td className="value4"> 
                            <div className="row">
                                <div className="column">
                                    ☐ YES
                                </div>
                                <div className="column">
                                    ☐ NO 
                                </div>
                            </div>
                        </td>
                    </tr>


                    <h4 className="test-info">Notes:</h4>
                    <div className="empty-space"></div>
                    <p className="footer">This form to be filed by Researcher.</p>


                </tbody>
            </table>
            
        </div>
    
    </>
    )
}

export default PrintComp;
