import React from 'react';
import {Link} from 'react-router-dom';
import '../../../css/CopyUserRecord.css';
import CopyUserCompKohala2 from '../components/CopyUserCompKohala2';

const CopyUserRecordKohala2 = () => {
    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    Create a copy of user!
                    <Link className="close-icon" to="/kohala2">X</Link>
                </h1>
            </div>
            <CopyUserCompKohala2/>
        </>  
    )
}

export default CopyUserRecordKohala2;