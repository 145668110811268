import React from 'react';
import {Link} from 'react-router-dom';
import '../../../css/CopyUserRecord.css';
import CopyUserCompWaianae from '../components/CopyUserCompWaianae';

const CopyUserRecord = () => {
    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    Create a copy of user!
                    <Link className="close-icon" to="/waianae">X</Link>
                </h1>
            </div>
            <CopyUserCompWaianae/>
        </>  
    )
}

export default CopyUserRecord;