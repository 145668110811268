import React from 'react';
import '../css/Home.css';

const Footer = () => {
    return (
        <div className="footer">
            <p>© 2021 Hawaii Integrated Analytics (Version 3.0.1)</p>
        </div>
    )
}

export default Footer;
