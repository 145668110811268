import React from 'react';
import Footer from '../../../components/Footer';
import Navbar from '../../../components/Navbar';
import SearchUserByDateLaupahoehoe from '../components/SearchUserByDateLaupahoehoe';
import '../../../css/Home.css';


const Home = () => {
    return (
        <>
            <div className="custom-home">
                <Navbar/>
                <div className="row-home">
                    <div className="col">
                        <div className="custom-header">
                        </div>
                    </div>
                    <SearchUserByDateLaupahoehoe/>
                </div>
            </div>
            <Footer/>  
        </>
    )
}

export default Home;
