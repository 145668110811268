import React from 'react';
import AddUserBI from '../components/AddUserBI';
import '../../../css/UpdateUser.css';
import {Link} from 'react-router-dom';

const CreateUserBI = () => {
    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    Create User Page
                    <Link className="close-icon" to="/bigisland">X</Link>
                </h1>
            </div>
            <AddUserBI/>
        </>
    )
}

export default CreateUserBI;
