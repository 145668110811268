import React, {useState, useEffect} from 'react';
import '../css/QRTest.css';
import QRComp from './QRComp';
//import GenQR from './GenQR';
//import QRcode from 'qrcode';


const QRTestComp = () => {

    const [lastKit, setLastKit] = useState();

    const [numOfKits, setNumOfKits] = useState("");

    const [data, setData] = useState([])


    useEffect(() => {
        const fetchLastKit = async () => {
            try{
                // eslint-disable-next-line
                const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/v4/xx/read');
                // console.log("hi Uri",Uri);
    
                const response = await fetch(Uri);
                // console.log("hi response",response);
    
                const json = await response.json();  
                // console.log("hi json",json);
                // console.log("last json",json[json.length - 1]);
                // console.log("last Record",json[json.length - 1].kitNumber);
    
                setLastKit(json[json.length - 1].kitNumber)
            } catch (error) {
                console.log(error);
            }
            
        }
        fetchLastKit();
        // eslint-disable-next-line
    }, []);



    const generateQR = async (e) => {
        e.preventDefault();
        
        const num = numOfKits;
        const QRs = [];

        const kit_no =  Number(lastKit.substring(lastKit.length - 5));
        const org = lastKit.slice(0, 2);

        for (let i = 1; i <= num; i++) {
            const newkitno = kit_no + i;
            if (newkitno.toString().length !== 5) {
                    var zeros = "";
                    for (let n = 1; n <= 5-newkitno.toString().length; n++){
                        zeros = zeros + "0"
                    }
            }
            const newkit = org + zeros + newkitno;

            QRs.push(
                <QRComp text={newkit}/>
                );
          }
        setData([QRs]) 
    }

    const getLastKit = async (e) => {
        e.preventDefault();
        try{
            // eslint-disable-next-line
            const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/v4/xx/read');
            // console.log("hi Uri",Uri);

            const response = await fetch(Uri);
            // console.log("hi response",response);

            const json = await response.json();  
            // console.log("hi json",json);
            // console.log("last json",json[json.length - 1]);
            // console.log("last Record",json[json.length - 1].kitNumber);

            setLastKit(json[json.length - 1].kitNumber)
        } catch (error) {
            console.log(error);
        }

    }

    return (
        <div>
            <h1>Hello QR Test</h1>

                <div className="row">

                    <div className="column">
                        <div style={{"marginTop":"40px", "marginBottom":"40px" }}>
                            <h4>How many QR codes do you need to generate?</h4><input id="numOfKits" value={numOfKits} onChange={e => {setNumOfKits(e.target.value)}}></input>
                        </div>
                        <button style={{"width":"300px", "marginBottom":"40px"}} type="submit" onClick={generateQR}>Generate Kit numbers and QR Code</button>
                        
                    </div>

                    <div className="column" style={{"alignItems":"center"}}>
                        <div style={{"marginTop":"40px", "marginBottom":"40px"}}>
                            <button onClick={getLastKit}>Get last kit</button>
                        </div>
                        <h4>Last Kit = {lastKit}</h4>
                    </div>

                </div>

                <div style={{"display": "flex", "flex-direction": "row", "padding":"4px", "flexWrap":"wrap",
                            "justify-content": "flex-start", "border":"2px solid black", "minHeight":"460px",}}>
                    {data}
                </div>

                {/* <div>
                    <GenQR text="hello new qr generator"/>
                </div> */}

                
        </div>
    )
}

export default QRTestComp;
