import React from 'react';
import UserDetailsCompKamaile from '../components/UserDetailsCompKamaile';
import '../../../css/UpdateUser.css';

const UserDetails = () => {

    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    User Details Page
                </h1>
            </div>
            <UserDetailsCompKamaile/>
        </>  
    )
}

export default UserDetails;
