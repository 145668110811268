import React from 'react';
import Footer from '../../../components/Footer';
//import Header from '../components/Header';
import Navbar from '../../../components/Navbar';
import SearchUserByDateMolokai from '../components/SearchUserByDateMolokai';
import '../../../css/Home.css';
//import DatePicker from '../components/DatePicker';
//import AddUser from '../components/AddUser';
//import DateInput from '../components/DateInput';
//import UserList from '../components/UserList';
//import LogoutComp from '../components/LogoutComp';

const Home = () => {
    return (
        <>
            
            <div className="custom-home">
                <Navbar/>
                <div className="row-home">
                    <div className="col">
                        <div className="custom-header">
                            {/*<Header/>*/}
                        </div>
                    </div>
                    <SearchUserByDateMolokai/>
                </div>
                {/*<div className="custom-date-input">
                    <DateInput/>
                </div>
                <UserList/>*/}
                
            </div>
            <Footer/>  
        </>
    )
}

export default Home;
