import React from 'react';
//import Header from '../../../components/Header';
import Navbar from '../../../components/Navbar';
import SearchUserByDateBI from '../components/SearchUserByDateBI';
import '../../../css/Home.css';
import Footer from '../../../components/Footer';

const Home = () => {
    return (
        <>
            
            <div className="custom-home">
                <Navbar/>
                <div className="row-home">
                    <div className="col">
                        <div className="custom-header">
                            {/*<Header/>*/}
                        </div>
                    </div>
                    <SearchUserByDateBI/>
                </div>
                
            </div>
            <Footer/> 
        </>
    )
}

export default Home;
