import React from 'react';
import UserDetailsCompBI from '../components/UserDetailsCompBI';
import '../../../css/UpdateUser.css';

const UserDetailsBI = () => {
    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    User Details Page
                </h1>
            </div>
            <UserDetailsCompBI/>
        </>  
    )
}

export default UserDetailsBI;
