import React, {useState, useEffect} from 'react';
import QRcode from 'qrcode';
import '../css/QR.css'

const GenQR = (props) => {

    const [src, setSrc] = useState('');

    useEffect(() => {
        QRcode.toDataURL(props.text).then((data) => {
            setSrc(data);
        });
        // eslint-disable-next-line
    }, [])

    return (
        <div className="gen-qr-main">
            <img alt={src} src={src}/>
        </div>
    )
}

export default GenQR;
