import firebase from 'firebase/app';

export const GetCurrentUserEmail = () => {

    const user = firebase.auth().currentUser;
    
    //console.log("hi");
    // console.log("user wrapper func email",user.uid);

    if (!user) {
        return null;
    } else {
        //console.log("user wrapper func email",user.email);
        const email = user.email;
        
        return {email}; 
    }
    
} 
