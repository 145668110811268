import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import '../../../css/UpdateUser.css';
import { MdPrint } from 'react-icons/md';



const UserDetailsComp = (props) => {

    let history = useHistory();

    const [registrationId, setRegistrationId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [phone1, setPhone1] = useState("");
    const [phone2, setPhone2] = useState("");
    const [personalEmail, setPersonalEmail] = useState("");
    const [parentEmail, setParentEmail] = useState("");
    const [appointmentDate, setAppointmentDate] = useState("");
    const [appointmentTime, setAppointmentTime] = useState("");
    const [location, setLocation] = useState("");
    const [consentId, setConsentId] = useState("");
    const [consent, setConsent] = useState("");
    const [consentNote, setConsentNote] = useState("");
    const [testResult, setTestResult] = useState("");
    const [testDate, setTestDate] = useState("");
    const [testNote, setTestNote] = useState("");
    const [payment, setPayment] = useState("");
    const [paymentNote, setPaymentNote] = useState("");
    const [smsStatus, setSmsStatus] = useState("");
    const [emailStatus, setEmailStatus] = useState("");
    const [techNote, setTechNote] = useState("");

    const [symptom, setSymptom] = useState("");
    const [exposure, setExposure] = useState("");
    const [vaccination, setVaccination] = useState("");

    const [gender, setGender] = useState("");
    const [race, setRace] = useState("");
    const [address, setAddress] = useState("");


    const [homeTestKit, setHomeTestKit] = useState("");
    
    //const { registrationID } = useParams();

    const { registrationID } = useParams();
    //console.log(registrationID);

    useEffect(() => {
        const fetchData = async () => {
            // eslint-disable-next-line
            const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/molokaiusers' + `/${registrationID}`);

            //console.log(Uri);
            try {
                const response = await fetch(Uri);
                const data = await response.json();

                setRegistrationId(data.registrationID)
                setFirstName(data.firstName);
                setLastName(data.lastName);
                setDob(data.dob);
                setPhone1(data.phone1);
                setPhone2(data.phone2);
                setPersonalEmail(data.personalEmail);
                setParentEmail(data.parentEmail);
                setAppointmentDate(data.appointmentDate);
                setAppointmentTime(data.appointmentTime);
                setLocation(data.location);
                setConsentId(data.consentID);
                setConsent(data.consent);
                setConsentNote(data.consentNote);
                setTestResult(data.testResult);
                setTestDate(data.testDate);
                setTestNote(data.testNote);
                setPayment(data.payment);
                setPaymentNote(data.paymentNote);
                setSmsStatus(data.smsStatus);
                setEmailStatus(data.emailStatus);
                setTechNote(data.techNote);
                setSymptom(data.symptom);
                setExposure(data.exposure);
                setVaccination(data.vaccination);
                setGender(data.gender);
                setRace(data.race);
                setAddress(data.address);

                setHomeTestKit(data.homeTestKit);

            } catch (error) {
                console.log(error);
            }
            
            /*const response = await UserFinder.get(`/${userid}`);

            var dateObj = new Date(response.data.data.users.dob);
            const day = dateObj.getDate()
            const month = dateObj.getMonth() + 1; //getMonth starts from 0
            const year = dateObj.getFullYear();
            const date = month + "-" + day + "-" + year; */
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    /*const handleUpdate = async (e) => {
        e.preventDefault(); //supressing default behavior for submit buttons only

        const updatedUser = await UserFinder.put(`/${userid}`, {
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            sex: gender,
            dob: dob,
            weight: weight,
            height: height,
            bmi: bmi,
            covidresult: covidresult
        });
        history.push("/");
    }*/


    
    const handleClose = () => {
        history.push(`/molokai`);
    }


    //const componentRef = useRef();
    //const handlePrint = useReactToPrint({content: () => componentRef.current});

    
    const handlePrint = (e, registrationID) => {
        e.stopPropagation();
        history.push(`/molokaiusers/${registrationID}/print`);
    }


    return (
        
        <div className="custom-update-container">

            <div className="print-wrapper">
                <MdPrint className="print-icon" onClick={(e) => handlePrint(e, registrationID)}/>
            </div>
            


            <form action="">
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="firstname">First Name</label>
                            <input disabled value={firstName} onChange={e => setFirstName(e.target.value)} type="text" className="form-control" placeholder="First Name"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="lastname">Last Name</label>
                            <input disabled value={lastName} onChange={e => setLastName(e.target.value)} type="text" className="form-control" placeholder="Last Name"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="registrationid">Registration ID</label>
                            <input disabled value={registrationId} onChange={e => setRegistrationId(e.target.value)} type="number" className="form-control" placeholder="#########"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="dob">Date of Birth</label>
                            <input disabled value={dob} onChange={e => setDob(e.target.value)} type="text" className="form-control" placeholder="Date of birth (mm/dd/yyyy)"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="personalemail">Email Address</label>
                            <input disabled value={personalEmail} onChange={e => setPersonalEmail(e.target.value)} type="email" className="form-control" placeholder="someone@gmail.com"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="phone1">Primary Phone</label>
                            <input disabled value={phone1} onChange={e => setPhone1(e.target.value)} type="text" className="form-control" placeholder="##########"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="phone2">Secondary Phone</label>
                            <input disabled value={phone2} onChange={e => setPhone2(e.target.value)} type="text" className="form-control" placeholder="##########"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="parentemail">Parent's Email Address</label>
                            <input disabled value={parentEmail} onChange={e => setParentEmail(e.target.value)} type="email" className="form-control" placeholder="someone@gmail.com"></input>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="gender">Gender</label>
                            <select disabled id="gender" className="input-group-text custom-drops" value={gender} onChange={e => setGender(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>

                    {/*<div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="race">Race</label>
                            <select disabled id="race" className="input-group-text custom-drops" value={race} onChange={e => setRace(e.target.value)}>
                                <option value="">N/A</option>
                                <option value="NH">Native Hawaiian</option>
                                <option value="PI">Pacific Islanders</option>
                                <option value="W">Caucasian</option>
                                <option value="A">Asian</option>
                                <option value="N">Native American</option>
                                <option value="B">Black</option>
                                <option value="O">Other</option>
                            </select>
                        </div>
    </div>*/}
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="race">Race</label>
                            <input disabled id="race" value={race} onChange={e => setRace(e.target.value)} type="text" className="form-control" placeholder=""></input>
                        </div>
                    </div>


                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="address">Address</label>
                            <input disabled id="address" value={address} onChange={e => setAddress(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="appointmentdate">Appointment Date</label>
                            <input disabled value={appointmentDate} onChange={e => setAppointmentDate(e.target.value)} type="text" className="form-control" placeholder="mm/dd/yyyy"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="appointmenttime">Appointment Time</label>
                            <input disabled value={appointmentTime} onChange={e => setAppointmentTime(e.target.value)} type="text" className="form-control" placeholder=""></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="location">Location</label>
                            <select disabled id="location" className="input-group-text" value={location} onChange={e => setLocation(e.target.value)}>
                                <option disabled>-- Location --</option>
                                <option value="molokai">Molokai Health Center</option>
                                <option value="waianae">Waianae High School</option>
                                <option value="waimanalo">Waimanalo</option>
                                <option value="bigisland">Big Island</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="testresult">Test Result</label>
                            <select disabled id="testresult" className="input-group-text" value={testResult} onChange={e => setTestResult(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Positive">Positive</option>
                                <option value="Negative">Negative</option>
                                <option value="Invalid">Invalid</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="testdate">Test Date</label>
                            <input disabled value={testDate} onChange={e => setTestDate(e.target.value)} type="text" className="form-control" placeholder="mm/dd/yyyy"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="testnote">Test Note</label>
                            <input disabled value={testNote} onChange={e => setTestNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-dropdowns">
                        <div className="form-group my-1">
                            <label htmlFor="symptom">Symptom</label>
                            <select disabled id="symptom" className="input-group-text custom-drops" value={symptom} onChange={e => setSymptom(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-dropdowns">
                        <div className="form-group my-1">
                            <label htmlFor="exposure">Exposure</label>
                            <select disabled id="exposure" className="input-group-text custom-drops" value={exposure} onChange={e => setExposure(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-dropdowns">
                        <div className="form-group my-1">
                            <label htmlFor="vaccination">Vaccination</label>
                            <select disabled id="vaccination" className="input-group-text custom-drops" value={vaccination} onChange={e => setVaccination(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-dropdowns">
                        <div className="form-group my-1">
                            <label htmlFor="homeTestKit">Home Test Kit</label>
                            <select disabled id="homeTestKit" className="input-group-text custom-drops" value={homeTestKit} onChange={e => setHomeTestKit(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>

                </div>



                <div className="row">
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="consentid">Consent ID</label>
                            <input disabled value={consentId} onChange={e => setConsentId(e.target.value)} type="number" className="form-control" placeholder="######"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="consent">Consent</label>
                            <select disabled id="consent" className="input-group-text" value={consent} onChange={e => setConsent(e.target.value)}>
                                <option value="N/A">N/A</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="consentnote">Consent Note</label>
                            <input disabled value={consentNote} onChange={e => setConsentNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="payment">Payment</label>
                            <input disabled value={payment} onChange={e => setPayment(e.target.value)} type="float" className="form-control" placeholder="$$"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="paymentnote">Payment Note</label>
                            <input disabled value={paymentNote} onChange={e => setPaymentNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="smsstatus">SMS Status</label>
                            <input disabled value={smsStatus} onChange={e => setSmsStatus(e.target.value)} type="text" className="form-control" placeholder="SMS Status"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="emailstatus">Email Status</label>
                            <input disabled value={emailStatus} onChange={e => setEmailStatus(e.target.value)} type="text" className="form-control" placeholder="Email Status"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="technote">Tech Note</label>
                            <input disabled value={techNote} onChange={e => setTechNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>
                <div className="d-grid col-3 mx-auto my-4">
                    <button type="submit" className="btn btn-primary" onClick={handleClose}>Close</button>
                </div>
            </form>
        </div>
    )
}

export default UserDetailsComp;
