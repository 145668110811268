import React, { useState, useEffect } from 'react';
import LogoutComp from '../../../components/LogoutComp';
import { Link } from 'react-router-dom';
import '../../../css/Home.css';
import { GetCurrentUser } from '../../../auth/GetCurrentUser';
import { useHistory } from 'react-router-dom';


const Navbar = () => {

    const [userID, setUserID] = useState('');

    useEffect(() => {
        const fetchUserID = async () => {
            // eslint-disable-next-line
            const userData = GetCurrentUser();    
            //console.log(userData);
            setUserID(userData.uid);
            
        }
        fetchUserID();
        // eslint-disable-next-line
    }, []);


    const history = useHistory();

    
    const handleAddUser = async (e) => {
        e.preventDefault();
        try{
            // eslint-disable-next-line
            const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/staff' + `/${userID}`);

            const response = await fetch(Uri);

            // console.log("response",response);

            const json = await response.json();

            // console.log("json",json);

            const org = await json.org;

            //console.log("org", org);

            if (org === "waianae"){     
                history.push('/waianaeusers/create');
            } else if (org === "bigIsland"){       
                history.push('/bigislandusers/create');
            } else if (org === "waimanalo"){       
                history.push('/waimanalousers/create');
            } else if (org === "kamaile"){       
                history.push('/kamaileusers/create');
            } else if (org === "molokai"){       
                history.push('/molokaiusers/create');
            } else if (org === "kohala"){       
                history.push('/kohalausers/create');
            } else if (org === "laupahoehoe"){       
                history.push('/laupahoehoeusers/create');
            } else if (org === "kohala2"){       
                history.push('/kohalausers2/create');
            } else if (org === "laupahoehoe2"){       
                history.push('/laupahoehoeusers2/create');
            } else {
                console.log("User has no roles, please contact support@hia.com");
            }
        } catch (error) {
            console.log(error);
        }

    }
    
    
    // const handleGenQR = async (e) => {
    //     history.push('/qr-wh');
    // }
    


    return (
        <>
            <nav className="navbar navbar-light bg-light">
                <div className="container-fluid">
                    <h1 className="navbar-brand">Welcome!</h1>
                    <form className="d-flex">
                        <div className="navbar-btn">
                            <Link to="/waianaeusers/qr">
                                <button className="btn btn-warning btn-features">Generate QR</button>
                            </Link>
                            <Link onClick={handleAddUser} to="">
                                <button className="btn btn-warning btn-features">Add User</button>
                            </Link>
                        </div>
                        <LogoutComp/>
                    </form>
                </div>
            </nav>
        </>
    )
}

export default Navbar;
