import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyD1IGlYm_m66lg2EWCo-bS8gmSojEHwGoM",
    authDomain: "paac-crud.firebaseapp.com",
    projectId: "paac-crud",
    storageBucket: "paac-crud.appspot.com",
    messagingSenderId: "1046288270456",
    appId: "1:1046288270456:web:e5fd50bcdefe4c9d7a198d",
    measurementId: "G-D3GHWC8JNF"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();


ReactDOM.render(<App/>, document.getElementById("root"));