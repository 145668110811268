import React from 'react';
import QRTestComp from '../components/QRTestComp';

const QRTest = () => {
    return (
        <div>
            <QRTestComp/>
        </div>
    )
}

export default QRTest;
