import React, {useState, useEffect, useRef } from 'react';
import '../../../css/QR.css';
import QRComp from './QRComp';
// import Popup from 'reactjs-popup';
import ReactToPrint from 'react-to-print';
import { GetCurrentUserEmail } from '../../../auth/GetCurrentUserEmail';
import { useHistory } from 'react-router-dom';

const QRFinalComponentWH = () => {

    const [lastKit, setLastKit] = useState();
    const [numOfKits, setNumOfKits] = useState("");
    const [data, setData] = useState([]);

    const [popupFlag, setPopupFlag] = useState(false);

    const [userEmail, setUserEmail] = useState();

    const componentRef = useRef();

    const history = useHistory();

    // /* To store values while creating record in backend */
    // const [db_kitNumber, setdb_kitNumber] = useState(null);
    // const [db_QRprintedFlag, setdb_QRprintedFlag] = useState(false);
    // const [db_QRprintedTime, setdb_QRprintedTime] = useState();
    // const [db_QRprintedBy, setdb_QRprintedBy] = useState();
    // const [db_QRremarks, setdb_QRremarks] = useState();


    useEffect(() => {
        const fetchLastKit = async () => {
            try{
                // eslint-disable-next-line
                const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/v4/wh/read');
    
                const response = await fetch(Uri);
        
                const json = await response.json();  
    
                setLastKit(json[json.length - 1].kitNumber)
            } catch (error) {
                console.log(error);
            }
            
        }

        const fetchUserID = async () => {
            // eslint-disable-next-line
            const userData = GetCurrentUserEmail();    
            //console.log("userData", userData.email);
            setUserEmail(userData.email);
            
            
        }
        fetchUserID();


        fetchLastKit();
        // eslint-disable-next-line
    }, []);

    const generateQR = async (e) => {
        e.preventDefault();
        
        const num = numOfKits;
        const QRs = [];

        const kit_no =  Number(lastKit.substring(lastKit.length - 5));
        const org = lastKit.slice(0, 2);

        for (let i = 1; i <= num; i++) {
            const newkitno = kit_no + i;
            if (newkitno.toString().length !== 5) {
                    var zeros = "";
                    for (let n = 1; n <= 5-newkitno.toString().length; n++){
                        zeros = zeros + "0"
                    }
            }
            const newkit = org + zeros + newkitno;

            QRs.push(
                <QRComp text={newkit}/>
                );
          }
        setData([QRs]) 
    }

    const getLastKit = async (e) => {
        e.preventDefault();
        try{
            // eslint-disable-next-line
            const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/v4/wh/read');           

            const response = await fetch(Uri);

            const json = await response.json();  

            setLastKit(json[json.length - 1].kitNumber)
        } catch (error) {
            console.log(error);
        }

    }

    const onAfterPrint = async (e) => {

        e.preventDefault();

        // console.log(data[0].length);
        // console.log(data[0]);

        if (data.length > 0) {
            console.log("New Kits created: ", data[0].length);
            for (let j = 0; j < data[0].length ; j++) {

                var currDateTime = new Date();
                var kitno_ = data[0][j].props.text;

                // console.log("kitno_", kitno_);
                // console.log("currDateTime", currDateTime);
                // console.log("userEmail", userEmail);


                try {
                    // eslint-disable-next-line
                    const response = await fetch('https://us-central1-paac-crud.cloudfunctions.net/app/api/v4/wh/create', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            
                                kitNumber: kitno_,
                                QRprintedFlag: true,
                                QRprintedTime: currDateTime,
                                QRprintedBy: userEmail,
                                QRremarks: "",
                                studentName: "",
                                studentDOB: currDateTime,
                                studentEmail: "",
                                studentPhone: "",
                                studentGender: "",
                                kitDistributionDate: currDateTime,
                                kitDistributedBy: "",
                                school: "",
                                studentSymptom: false,
                                studentExposure: false,
                                studentVaccination: false,
                                kitDistributionNote: "",
                                TTname: "",
                                TTemail: "",
                                TTphone: "",
                                TTdob: currDateTime,
                                testDatetime: currDateTime,
                                testResult: "",
                                TTexposure: false,
                                TTsymptom: false,
                                TTVaccination: false,
                                relationshipStudent: "",
                                TTnote: "",
                            
                        })
                    })
                    //console.log(response);

                    setData([]);
                    setPopupFlag(false);

                    try{
                        // eslint-disable-next-line
                        const Uri = new URL('https://us-central1-paac-crud.cloudfunctions.net/app/api/v4/wh/read');
            
                        const response = await fetch(Uri);
                
                        const json = await response.json();  
            
                        setLastKit(json[json.length - 1].kitNumber)
                    } catch (error) {
                        console.log(error);
                    }

                    history.push("/waianaeusers/qr");

                } catch (error) {
                    console.log(error);
                }
            }
        }


    }




    const onAfterCancel = async (e) => {
        //     e.preventDefault();
        setPopupFlag(false);
    }




    // const afterPrint = async (e) => {
    //     setPopupWindow(
    //         <Popup trigger={<button> Trigger</button>} position="right center">
    //             <div>Popup content here !!</div>
    //         </Popup>
    //     )
    // }

    // const afterPrint = async (e) => {
    //     e.preventDefault();

    //     if (data.length > 0) {
    //         console.log("New Kits created: ", data[0].length);
    //         for (let j = 0; j < data[0].length ; j++) {
    //             console.log(data[0][j].props.text);
    //         }
    //     }

    //     try {
    //         // eslint-disable-next-line
    //             const response = await fetch('https://us-central1-paac-crud.cloudfunctions.net/app/api/v4/xx/create', {
    //                 method: 'POST',
    //                 headers: { 'Content-Type': 'application/json' },
    //                 body : JSON.stringify({
    //                     registrationID: registrationId,
    //                     firstName: firstName,
    //                     lastName: lastName,
    //                     dob: dob,
    //                     phone1: phone1,
    //                     phone2: phone2,
    //                     personalEmail: personalEmail,
    //                     parentEmail: parentEmail,
    //                     appointmentDate: appointmentDate,
    //                     appointmentTime: appointmentTime,
    //                     location: location,
    //                     consentID: consentId,
    //                     consent: consent,
    //                     consentNote: consentNote,                            
    //                     testResult: testResult,
    //                     testDate: testDate,
    //                     testNote: testNote,
    //                     payment: payment,
    //                     paymentNote: paymentNote,
    //                     smsStatus: smsStatus,
    //                     emailStatus: emailStatus,
    //                     techNote: techNote,
    //                     symptom: symptom,
    //                     exposure: exposure,
    //                     vaccination: vaccination,
    //                     gender: gender,
    //                     race: finalrace,
    //                     address: address,
    //                     homeTestKit: homeTestKit,
    //                 })
    //             });
    //             //console.log(response);
    //             history.push("/waianae");
    //         } catch (error) {
    //             console.log(error);
    //         }
    // }


    
    
    

    


    return (
        <div>
                <div className="row d-print-none">

                    <div className="column">
                        <div className="head-wrapper input-group mb-3">
                            <h4>How many QR codes do you need to generate?</h4>
                            <input className="form-control" placeholder="" id="numOfKits" value={numOfKits} onChange={e => {setNumOfKits(e.target.value)}}></input>
                            <button className="qr-btn btn btn-outline-secondary" type="submit" onClick={generateQR}>Generate QR</button> 
                        </div>
                           
                    </div>

                    <div className="column2">
                        <div className="last-kit input-group mb-3">
                            <h5 className="input-group-text">Last Kit: <input className="last-kit-val form-control" value={lastKit}></input></h5>
                            <button className="btn btn-outline-secondary" type="button" onClick={getLastKit}>Refresh</button>
                        </div>   
                    </div>

                </div>

                <div className="row">

                    <div className="column3">
                        <ReactToPrint
                            trigger={() => <button type="button" className="btn btn-outline-primary print-btn">Print</button>}
                            content={() => componentRef.current}
                            // onAfterPrint={() => console.log("Clicked")}
                            onAfterPrint={() => setPopupFlag(true)}
                        />
                    </div>

                    <div className="column4">
                        {popupFlag && 
                        <div className="qr-popup">
                            <h1>Did you print the kits?</h1>
                            <button type="button" className="btn btn-primary qr-popup-btn" onClick={onAfterPrint}>Yes</button>
                            <button type="button" className="btn btn-secondary qr-popup-btn" onClick={onAfterCancel}>No</button>
                        </div>}
                    </div>

                </div>

                <div className="qr-display" ref={componentRef}>
                    {data}
                </div>

                
        </div>
    )
}

export default QRFinalComponentWH;


