import React from 'react';
import '../css/QR.css'
import {Link} from 'react-router-dom';
import QRFinalComponent from '../components/QRFinalComponent';


const QR = () => {
    return (
        <>
            <div className="custom-qr-wrapper">
                <h1 className="text-center d-print-none">
                    Generate QR Codes
                    <Link className="close-icon" to="/waianae">X</Link>
                </h1>
            </div>
            <QRFinalComponent/>
        </>
    )
}

export default QR;
