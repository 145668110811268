import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './routes/LoginPage';
import { ProtectedRoute, useAuth } from './auth';
import Home from './routes/Home.js';

import UpdateUser from './routes/Waianae/routes/UpdateUser';
import UserDetails from './routes/Waianae/routes/UserDetails';
import WaianaeHome from './routes/Waianae/routes/WaianaeHome';
import CreateUser from './routes/Waianae/routes/CreateUser';
import PrintPage from './routes/Waianae/routes/PrintPage';
import CopyUserRecordWaianae from './routes/Waianae/routes/CopyUserRecordWaianae';
import QRWH from './routes/Waianae/routes/QRWH';


import UpdateUserBI from './routes/BigIsland/routes/UpdateUserBI';
import UserDetailsBI from './routes/BigIsland/routes/UserDetailsBI';
import BIHome from './routes/BigIsland/routes/BIHome';
import CreateUserBI from './routes/BigIsland/routes/CreateUserBI';
import PrintPageBI from './routes/BigIsland/routes/PrintPageBI';
import CopyUserRecordBI from './routes/BigIsland/routes/CopyUserRecordBI';


import UpdateUserWaimanalo from './routes/Waimanalo/routes/UpdateUserWaimanalo';
import UserDetailsWaimanalo from './routes/Waimanalo/routes/UserDetailsWaimanalo';
import WaimanaloHome from './routes/Waimanalo/routes/WaimanaloHome';
import CreateUserWaimanalo from './routes/Waimanalo/routes/CreateUserWaimanalo';
import PrintPageWaimanalo from './routes/Waimanalo/routes/PrintPageWaimanalo';
import CopyUserRecordWaimanalo from './routes/Waimanalo/routes/CopyUserRecordWaimanalo';


import UpdateUserKamaile from './routes/Kamaile/routes/UpdateUserKamaile';
import UserDetailsKamaile from './routes/Kamaile/routes/UserDetailsKamaile';
import KamaileHome from './routes/Kamaile/routes/KamaileHome';
import CreateUserKamaile from './routes/Kamaile/routes/CreateUserKamaile';
import CopyUserRecord from './routes/Kamaile/routes/CopyUserRecord';


import UpdateUserMolokai from './routes/Molokai/routes/UpdateUser';
import UserDetailsMolokai from './routes/Molokai/routes/UserDetails';
import MolokaiHome from './routes/Molokai/routes/MolokaiHome';
import CreateUserMolokai from './routes/Molokai/routes/CreateUser';
import PrintPageMolokai from './routes/Molokai/routes/PrintPage';
import CopyUserRecordMolokai from './routes/Molokai/routes/CopyUserRecordMolokai';


import UpdateUserKohala from './routes/Kohala/routes/UpdateUserKohala';
import UserDetailsKohala from './routes/Kohala/routes/UserDetailsKohala';
import KohalaHome from './routes/Kohala/routes/KohalaHome';
import CreateUserKohala from './routes/Kohala/routes/CreateUserKohala';
import CopyUserRecordKohala from './routes/Kohala/routes/CopyUserRecordKohala';


import UpdateUserLaupahoehoe from './routes/Laupahoehoe/routes/UpdateUserLaupahoehoe';
import UserDetailsLaupahoehoe from './routes/Laupahoehoe/routes/UserDetailsLaupahoehoe';
import LaupahoehoeHome from './routes/Laupahoehoe/routes/LaupahoehoeHome';
import CreateUserLaupahoehoe from './routes/Laupahoehoe/routes/CreateUserLaupahoehoe';
import CopyUserRecordLaupahoehoe from './routes/Laupahoehoe/routes/CopyUserRecordLaupahoehoe';


import UpdateUserKohala2 from './routes/Kohala2/routes/UpdateUserKohala2';
import UserDetailsKohala2 from './routes/Kohala2/routes/UserDetailsKohala2';
import Kohala2Home from './routes/Kohala2/routes/Kohala2Home';
import CreateUserKohala2 from './routes/Kohala2/routes/CreateUserKohala2';
import PrintPageKohala2 from './routes/Kohala2/routes/PrintPageKohala2';
import CopyUserRecordKohala2 from './routes/Kohala2/routes/CopyUserRecordKohala2';


import UpdateUserLaupahoehoe2 from './routes/Laupahoehoe2/routes/UpdateUserLaupahoehoe2';
import UserDetailsLaupahoehoe2 from './routes/Laupahoehoe2/routes/UserDetailsLaupahoehoe2';
import Laupahoehoe2Home from './routes/Laupahoehoe2/routes/Laupahoehoe2Home';
import CreateUserLaupahoehoe2 from './routes/Laupahoehoe2/routes/CreateUserLaupahoehoe2';
import PrintPageLaupahoehoe2 from './routes/Laupahoehoe2/routes/PrintPageLaupahoehoe2';


import Test from './routes/Waimanalo/routes/Test';
import QRTest from './routes/QRTest';
import QR from './routes/QR';


const App = () => {

    const {isLoading, user} = useAuth();

    return (
        <>
            {/*<UserContextProvider>*/}
            <div className="container-sm">
                <Router>
                    <Switch>
                        <Route exact path="/login" component={Login}/>

                        <Route exact path="/test" component={Test}/>

                        <Route exact path="/qrtest" component={QRTest}/>

                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/qr" component={QR}/>

                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/" component={Home}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waianae" component={WaianaeHome}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/bigisland" component={BIHome}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waimanalo" component={WaimanaloHome}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kamaile" component={KamaileHome}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/molokai" component={MolokaiHome}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kohala" component={KohalaHome}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/laupahoehoe" component={LaupahoehoeHome}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kohala2" component={Kohala2Home}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/laupahoehoe2" component={Laupahoehoe2Home}/>

                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waianaeusers/:registrationID/update" component={UpdateUser}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waianaeusers/:registrationID/print" component={PrintPage}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waianaeusers/qr" component={QRWH}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waianaeusers/create" component={CreateUser}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waianaeusers/:registrationID" component={UserDetails}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waianaeusers/:registrationID/copy" component={CopyUserRecordWaianae}/> 
                        

                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kamaileusers/:registrationID/update" component={UpdateUserKamaile}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kamaileusers/:registrationID/copy" component={CopyUserRecord}/> 
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kamaileusers/create" component={CreateUserKamaile}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kamaileusers/:registrationID" component={UserDetailsKamaile}/>

                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/bigislandusers/:registrationID/update" component={UpdateUserBI}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/bigislandusers/:registrationID/print" component={PrintPageBI}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/bigislandusers/create" component={CreateUserBI}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/bigislandusers/:registrationID" component={UserDetailsBI}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/bigislandusers/:registrationID/copy" component={CopyUserRecordBI}/> 


                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waimanalousers/:registrationID/update" component={UpdateUserWaimanalo}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waimanalousers/:registrationID/print" component={PrintPageWaimanalo}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waimanalousers/create" component={CreateUserWaimanalo}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waimanalousers/:registrationID" component={UserDetailsWaimanalo}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/waimanalousers/:registrationID/copy" component={CopyUserRecordWaimanalo}/> 


                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/molokaiusers/:registrationID/update" component={UpdateUserMolokai}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/molokaiusers/:registrationID/print" component={PrintPageMolokai}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/molokaiusers/create" component={CreateUserMolokai}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/molokaiusers/:registrationID" component={UserDetailsMolokai}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/molokaiusers/:registrationID/copy" component={CopyUserRecordMolokai}/> 

                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kohalausers/:registrationID/update" component={UpdateUserKohala}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kohalausers/:registrationID/copy" component={CopyUserRecordKohala}/> 
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kohalausers/create" component={CreateUserKohala}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kohalausers/:registrationID" component={UserDetailsKohala}/>

                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/laupahoehoeusers/:registrationID/update" component={UpdateUserLaupahoehoe}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/laupahoehoeusers/:registrationID/copy" component={CopyUserRecordLaupahoehoe}/> 
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/laupahoehoeusers/create" component={CreateUserLaupahoehoe}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/laupahoehoeusers/:registrationID" component={UserDetailsLaupahoehoe}/>

                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kohalausers2/:registrationID/update" component={UpdateUserKohala2}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kohalausers2/:registrationID/print" component={PrintPageKohala2}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kohalausers2/create" component={CreateUserKohala2}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kohalausers2/:registrationID" component={UserDetailsKohala2}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/kohalausers2/:registrationID/copy" component={CopyUserRecordKohala2}/> 

                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/laupahoehoeusers2/:registrationID/update" component={UpdateUserLaupahoehoe2}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/laupahoehoeusers2/:registrationID/print" component={PrintPageLaupahoehoe2}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/laupahoehoeusers2/create" component={CreateUserLaupahoehoe2}/>
                        <ProtectedRoute isAuthed={!!user} isLoading={isLoading} exact path="/laupahoehoeusers2/:registrationID" component={UserDetailsLaupahoehoe2}/>
                       
                      
                    </Switch>
                </Router>
            </div>
            {/*</UserContextProvider> */}
        </>
    )
}

export default App;