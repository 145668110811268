import React from 'react';
import UserDetailsCompLaupahoehoe2 from '../components/UserDetailsCompLaupahoehoe2';
import '../../../css/UpdateUser.css';

const UserDetailsLaupahoehoe2 = () => {

    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    User Details Page
                </h1>
            </div>
            <UserDetailsCompLaupahoehoe2 />
        </>  
    )
}

export default UserDetailsLaupahoehoe2;
