import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import '../../../css/UpdateUser.css';
import { customAlphabet } from 'nanoid';


const AddUserBI = (props) => {

    let history = useHistory();

    const nanoid = customAlphabet('1234567890', 8);
    const newRegistrationID = nanoid();

    const [registrationId, setRegistrationId] = useState(newRegistrationID);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [dob, setDob] = useState("");
    const [phone1, setPhone1] = useState("");
    const [phone2, setPhone2] = useState("");
    const [personalEmail, setPersonalEmail] = useState("");
    const [parentEmail, setParentEmail] = useState("");
    const [appointmentDate, setAppointmentDate] = useState("");
    const [appointmentTime, setAppointmentTime] = useState("");
    const [location, setLocation] = useState("");
    const [consentId, setConsentId] = useState("");
    const [consent, setConsent] = useState("");
    const [consentNote, setConsentNote] = useState("");
    const [testResult, setTestResult] = useState("");
    const [testDate, setTestDate] = useState("");
    const [testNote, setTestNote] = useState("");
    const [payment, setPayment] = useState("");
    const [paymentNote, setPaymentNote] = useState("");
    const [smsStatus, setSmsStatus] = useState("");
    const [emailStatus, setEmailStatus] = useState("");
    const [techNote, setTechNote] = useState("");


    //adding today's date to appointment date
    if (appointmentDate === ""){

        var currdate = new Date ();
        const year = currdate.getFullYear();
        const month = ("0" + (currdate.getMonth() + 1)).slice(-2);
        const day = ("0" + currdate.getDate()).slice(-2);
        const today = month + "/" + day + "/" + year;

        setAppointmentDate(today);
    }
    

    const [ firstNameError, setFirstNameError] = useState("");
    const [ lastNameError, setLastNameError] = useState("");
    const [ dobError, setDobError] = useState("");
    const [ phone1Error, setPhone1Error] = useState("");
    const [ appointmentDateError, setAppointmentDateError] = useState("");

    //NEW
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (firstName.length === 0){
            setFirstNameError("Required field!");
        } else if (lastName.length === 0){
            setLastNameError("Required field!");
        } else if (dob.length === 0){
            setDobError("Required field!");
        } else if (phone1.length === 0){
            setPhone1Error("Required field!");
        } else if (appointmentDate.length === 0){
            setAppointmentDateError("Required field!");
        } else {
            try {
                
                // eslint-disable-next-line
                    const response = await fetch('https://us-central1-paac-crud.cloudfunctions.net/app/api/bigislandusers/create', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body : JSON.stringify({
                            registrationID: registrationId,
                            firstName: firstName,
                            lastName: lastName,
                            dob: dob,
                            phone1: phone1,
                            phone2: phone2,
                            personalEmail: personalEmail,
                            parentEmail: parentEmail,
                            appointmentDate: appointmentDate,
                            appointmentTime: appointmentTime,
                            location: location,
                            consentID: consentId,
                            consent: consent,
                            consentNote: consentNote,                            
                            testResult: testResult,
                            testDate: testDate,
                            testNote: testNote,
                            payment: payment,
                            paymentNote: paymentNote,
                            smsStatus: smsStatus,
                            emailStatus: emailStatus,
                            techNote: techNote
                        })
                    });
                    //console.log(response);
                    history.push("/bigisland");
                } catch (error) {
                    console.log(error);
                }
        }
    }

    return (
        <div className="custom-update-container">
            <form action="" >
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <div className="row">
                                <div className="col">
                                    <label className="required-field" htmlFor="firstname">First Name</label>
                                </div>
                                <div className="col">
                                    { firstNameError.length > 0 ? (
                                    <p style={{color: "red", fontSize:"small", marginLeft: `-150px`, padding:"none" }} >{firstNameError}</p>
                                    ) : null }
                                </div>
                            </div> 
                            <input id="firstname" name="firstname" value={firstName} onChange={e => {setFirstName(e.target.value); setFirstNameError("")}} type="text" className="form-control" placeholder="First Name"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <div className="row">
                                <div className="col">
                                    <label className="required-field" htmlFor="lastname">Last Name</label>
                                </div>
                                <div className="col">
                                    { lastNameError.length > 0 ? (
                                    <p style={{color: "red", fontSize:"small", marginLeft: `-150px`, padding:"none" }} >{lastNameError}</p>
                                    ) : null }
                                </div>
                            </div>
                            <input id="lastname" name="lastname" value={lastName} onChange={e => {setLastName(e.target.value); setLastNameError("")}} type="text" className="form-control" placeholder="Last Name"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <label className="required-field" htmlFor="registrationid">Registration ID</label>
                            <input name="registrationID" id="registrationid" value={registrationId} onChange={e => setRegistrationId(e.target.value)} type="string" className="form-control" placeholder="#########"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <div className="row">
                                <div className="col">
                                    <label className="required-field" htmlFor="dob">Date of Birth</label>
                                </div>
                                <div className="col">
                                    { dobError.length > 0 ? (
                                    <p style={{color: "red", fontSize:"small", marginLeft: `-50px`, padding:"none" }} >{dobError}</p>
                                    ) : null }
                                </div>
                            </div>
                            <input name="dob" id="dob" value={dob} onChange={e => {setDob(e.target.value); setDobError("")}} type="text" className="form-control" placeholder="Date of birth (mm/dd/yyyy)"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="personalemail">Email Address</label>
                            <input id="personalemail" value={personalEmail} onChange={e => setPersonalEmail(e.target.value)} type="email" className="form-control" placeholder="someone@gmail.com"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <div className="row">
                                <div className="col">
                                    <label className="required-field" htmlFor="phone1">Primary Phone</label>
                                </div>
                                <div className="col">
                                    { phone1Error.length > 0 ? (
                                    <p style={{color: "red", fontSize:"small", marginLeft: `-40px`, padding:"none" }} >{phone1Error}</p>
                                    ) : null }
                                </div>
                            </div>
                            
                            <input name="phone1" id="phone1" value={phone1} onChange={e => {setPhone1(e.target.value); setPhone1Error("")}} type="text" className="form-control" placeholder="##########"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="phone2">Secondary Phone</label>
                            <input id="phone2" value={phone2} onChange={e => setPhone2(e.target.value)} type="text" className="form-control" placeholder="##########"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="parentemail">Parent's Email Address</label>
                            <input id="parentemail" value={parentEmail} onChange={e => setParentEmail(e.target.value)} type="email" className="form-control" placeholder="someone@gmail.com"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <div className="row">
                                <div className="col">
                                    <label className="required-field" htmlFor="appointmentdate">Appointment Date</label>
                                </div>
                                <div className="col">
                                    { appointmentDateError.length > 0 ? (
                                    <p style={{color: "red", fontSize:"small", marginLeft: `-40px`, padding:"none" }} >{appointmentDateError}</p>
                                    ) : null }
                                </div>
                            </div>
                            <input name="appointmentdate" id="appointmentdate" value={appointmentDate} onChange={e => {setAppointmentDate(e.target.value); setAppointmentDateError("")}} type="text" className="form-control" placeholder="mm/dd/yyyy"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="appointmenttime">Appointment Time</label>
                            <input id="appointmenttime" value={appointmentTime} onChange={e => setAppointmentTime(e.target.value)} type="text" className="form-control" placeholder=""></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="location">Location</label>
                            <select id="location" className="input-group-text" value={location} onChange={e => setLocation(e.target.value)}>
                                <option disabled>-- Location --</option>
                                <option value="bigisland">Big Island</option>
                                <option value="waianae">Waianae High School</option>
                                <option value="kohala">Kohala Highschool</option>
                                <option value="laupahoehoe">Laupahoehoe Charter School</option>
                                <option value="kamaile">Kamaile</option>
                                <option value="waimanalo">Waimanalo</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="testresult">Test Result</label>
                            <select id="testresult" className="input-group-text" value={testResult} onChange={e => setTestResult(e.target.value)}>
                                <option value="">N/A</option>
                                <option value="P">Positive</option>
                                <option value="N">Negative</option>
                                <option value="I">Invalid</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="testdate">Test Date</label>
                            <input id="testdate" value={testDate} onChange={e => setTestDate(e.target.value)} type="text" className="form-control" placeholder="mm/dd/yyyy"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="testnote">Test Note</label>
                            <input id="testnote" value={testNote} onChange={e => setTestNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="consentid">Consent ID</label>
                            <input id="consentid" value={consentId} onChange={e => setConsentId(e.target.value)} type="number" className="form-control" placeholder="######"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="consent">Consent</label>
                            <select id="consent" className="input-group-text" value={consent} onChange={e => setConsent(e.target.value)}>
                                <option value="NA">N/A</option>
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="consentnote">Consent Note</label>
                            <input id="consentnote" value={consentNote} onChange={e => setConsentNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <div className="form-group my-1">
                            <label htmlFor="payment">Payment</label>
                            <input id="payment" value={payment} onChange={e => setPayment(e.target.value)} type="float" className="form-control" placeholder="$$"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="paymentnote">Payment Note</label>
                            <input id="paymentnote" value={paymentNote} onChange={e => setPaymentNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="smsstatus">SMS Status</label>
                            <input id="smsstatus" value={smsStatus} onChange={e => setSmsStatus(e.target.value)} type="text" className="form-control" placeholder="SMS Status"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="emailstatus">Email Status</label>
                            <input id="emailstatus" value={emailStatus} onChange={e => setEmailStatus(e.target.value)} type="text" className="form-control" placeholder="Email Status"></input>
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group my-1">
                            <label htmlFor="technote">Tech Note</label>
                            <input id="technote" value={techNote} onChange={e => setTechNote(e.target.value)} type="text" className="form-control" placeholder="Note"></input>
                        </div>
                    </div>
                </div>
                <div className="d-grid col-3 mx-auto my-4">
                    <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Create User</button>
                </div>
            </form>
        </div>
    )
} 

export default AddUserBI;

