import React from 'react';
import GenQR from './GenQR';
import '../../../css/QR.css';

const QRComp = ({text}) => {

    // const survey = "https://www.qualtrics.com/survey-id/" + text.toString()


    const survey = "https://manoahawaiiss.az1.qualtrics.com/jfe/form/SV_3aDE2I5hHnuOLf8?kitID=" + text.toString()

    // const survey = "https://bit.ly/3OjAkuY" + text.toString()

    return (
        <div className="qr-comp-wrapper">
            <div className="qr-comp-container" key={text}>Kit No - {text}</div>
            <GenQR text={survey}/>
        </div>
    )
}

export default QRComp;
