import React from 'react';
import AddUser from '../components/AddUser';
//import '../css/CreateUser.css'
import '../../../css/UpdateUser.css';
import {Link} from 'react-router-dom';

const CreateUser = () => {
    return (
        <>
            <div className="custom-update">
                <h1 className="text-center">
                    Create User Page
                    <Link className="close-icon" to="/waianae">X</Link>
                </h1>
            </div>
            <AddUser/>
        </>
    )
}

export default CreateUser;
