import React, {useState} from 'react';

const Test = () => {


    const [ race_NH, setrace_NH] = useState(false);
    const [ race_PI, setrace_PI] = useState(false);
    const [ race_C, setrace_C] = useState(false);
    const [ race_A, setrace_A] = useState(false);
    const [ race_NA, setrace_NA] = useState(false);
    const [ race_B, setrace_B] = useState(false);
    const [ race_O, setrace_O] = useState(false);


    const handleChangeNH = async (e) => {
        setrace_NH(!race_NH);
    }

    const handleChangePI = async (e) => {
        setrace_PI(!race_PI);
    }

    const handleChangeC = async (e) => {
        setrace_C(!race_C);
    }

    const handleChangeA = async (e) => {
        setrace_A(!race_A);
    }

    const handleChangeNA = async (e) => {
        setrace_NA(!race_NA);
    }

    const handleChangeB = async (e) => {
        setrace_B(!race_B);
    }

    const handleChangeO = async (e) => {
        setrace_O(!race_O);
    }
    


    const handleSubmit = async (e) => {
        let race = "";
        
        if (race_NH){
            race = race + "Native Hawaiian"; 
        }
        if (race_PI){
            if (race.length === 0) {
                race = race + "Pacific Islander";
            }
            else{
                race = race + ",Pacific Islander";
            }
        }
        if (race_C){
            if (race.length === 0) {
                race = race + "Caucasian";
            }
            else{
                race = race + ",Caucasian";
            }
        }
        if (race_A){
            if (race.length === 0) {
                race = race + "Asian";
            }
            else{
                race = race + ",Asian";
            }
        }
        if (race_NA){
            if (race.length === 0) {
                race = race + "Native American";
            }
            else{
                race = race + ",Native American";
            }
        }
        if (race_B){
            if (race.length === 0) {
                race = race + "Black";
            }
            else{
                race = race + ",Black";
            }
        }
        if (race_O){
            if (race.length === 0) {
                race = race + "Others";
            }
            else{
                race = race + ",Others";
            }
        }

        console.log(race);
    }

    

    return (
        <div>
            <h1>Hello</h1>

            <div>
                <label>
                    <input 
                        type="checkbox"
                        label="NH"
                        value={race_NH}
                        onChange={handleChangeNH}
                    />
                    Native Hawaiian
                </label>
                <label>
                    <input 
                        type="checkbox"
                        label="PI"
                        value={race_PI}
                        onChange={handleChangePI}
                    />
                    Pacific Islander
                </label>
                <label>
                    <input 
                        type="checkbox"
                        label="C"
                        value={race_C}
                        onChange={handleChangeC}
                    />
                    Caucasian
                </label>
                <label>
                    <input 
                        type="checkbox"
                        label="A"
                        value={race_A}
                        onChange={handleChangeA}
                    />
                    Asian
                </label>
                <label>
                    <input 
                        type="checkbox"
                        label="NA"
                        value={race_NA}
                        onChange={handleChangeNA}
                    />
                    Native American
                </label>
                <label>
                    <input 
                        type="checkbox"
                        label="B"
                        value={race_B}
                        onChange={handleChangeB}
                    />
                    Black
                </label>
                <label>
                    <input 
                        type="checkbox"
                        label="O"
                        value={race_O}
                        onChange={handleChangeO}
                    />
                    Other
                </label>

            </div>


            <button onClick={handleSubmit}>
                Save
            </button>

            
            
        </div>
    )
}

export default Test;

